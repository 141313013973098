/** @jsx jsx */

import "../scss/main.scss";
import React from "react"
import Header from "./header";
import Footer from "./footer";

import { css, jsx } from "@emotion/core";
import {GlobalStyles} from "../styles/globalstyles";
import {DgsPlayer} from "./dgsplayer";

const Layout = ({ children, longPage }) => {

    const mainStyle = css`
        width: 100%;
        ${ !longPage && GlobalStyles.mainAreaHeight}
        ${GlobalStyles.mainAreaStyle}
    `;

  return (
    <>
        <DgsPlayer />
        <Header />
        <div css={css`font-size: 1.5rem; width: 100%; height: 100%; margin: auto 0;`}>
            <main css={mainStyle}>
                {children}
            </main>
        </div>
        <Footer />
    </>
  )
}


export default Layout;
