/** @jsx jsx */

import Player from '@vimeo/player';
import React from 'react';
import {jsx, css} from "@emotion/core";
import {GlobalIcons, GlobalStyles} from "../styles/globalstyles";


// This is a singleton component!
export class DgsPlayer extends React.Component {

    playerRef;

    constructor(props) {
        super(props);

        this.state = {
            play: false,
            vimeoVideoId: null
        };

        DgsPlayer.__singletonRef = this;

        this.playerRef = React.createRef();
        this.player = null;
    }

    static play(vimeoVideoId, startCallback, stopCallback) {
        DgsPlayer.__singletonRef.__play(vimeoVideoId, startCallback, stopCallback);
    }

    static stop() {
        DgsPlayer.__singletonRef.__stop();
    }

    __play(vimeoVideoId, startCallback, stopCallback) {

        const startVideo = () => {
            this.player.play();
            startCallback();
        };

        if(this.player === null) {
            this.player = new Player('dgs-vimeo-player', {
                id: vimeoVideoId,
                controls: false,
                title: false,
                portrait: false,
                byline: false,
            });

            this.player.on('ended', this.playerEventEnded);

            this.player.ready().then( startVideo );
        }
        else if(this.state.vimeoVideoId !== vimeoVideoId) {
            this.state.stopCallback && this.state.stopCallback();

            this.player.loadVideo(vimeoVideoId).then( startVideo );
        }

        this.setState({
            vimeoVideoId: vimeoVideoId,
            play: true,
            stopCallback
        });
    }

    __stop() {
        if(!this.player) return;

        this.player.pause();
        this.player.setCurrentTime(0);
        this.state.stopCallback && this.state.stopCallback();

        this.setState({
            play: false,
            stopCallback: null,
            vimeoVideoId: null,
        });
    }

    render() {
        const playerStyle = css`
          ${!this.state.play && "display: none;"}
          position: fixed;
          z-index: 10;
          bottom: 0;
          left: 0;
          border: 1px solid grey;
        `;

        return <div css={playerStyle} ref={this.playerRef} id={"dgs-vimeo-player"} />
    }

    playerEventEnded = () => {
        this.__stop();
    }

}


export class DgsPlayButton extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            play: false
        };
    }

    render() {

        const buttonStyle = css`
            ${this.state.play ? GlobalIcons.pauseIcon : GlobalIcons.playDGSSmallIcon}
            transition: background-image 0.25s;
            width: 3rem;
            height: 3rem;
            border: 0;
            cursor: pointer;
            background-color: unset;
            background-repeat: no-repeat;
            background-size: contain;
        `;

        return <button css={buttonStyle} onClick={this.handleClick} />;
    }

    handleClick= () => {
        if(this.state.play) {
            DgsPlayer.stop();
        }
        else {
            DgsPlayer.play(this.props.vimeoVideoId, () => {
                    this.setState({play: true})
                },
                () => {
                    this.setState({play: false})
                }
            );
        }
    }
}